import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from "../../../components/ui/CustomButton"; // Assuming you have a custom button component
import { Text } from '../../../components/ui/Text';
import { attachpaymentMethodToCustomer, createCustomer, getAllCustomers } from '../../../utils/apis/payment_repository';
import { getUserFromLocalStorage } from '../../../utils/helper';
import { showToast } from '../../../utils/toast';
interface CreditCardProps {
  onSaveSuccess?:() => void;
}
const CreditCardForm = ({onSaveSuccess}: CreditCardProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentDetailsValid, setPaymentDetailsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = getUserFromLocalStorage();
  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const handleCardChange = (event: { complete: boolean | ((prevState: boolean) => boolean); }) => {
    // Handle real-time validation errors from the CardElement
    setPaymentDetailsValid(event.complete);
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {

    event.preventDefault();
setLoading(true);
    if (!stripe || !elements) {
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
        return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
    });

    if (error) {
      console.log(error.message);
    } else {
      console.log('PaymentMethod ID:', paymentMethod.id);
      // Here you can handle the result and save the payment method ID
    }


    try {
        const debitCustomers = await getAllCustomers(currentUser.email);
        console.log("debitCustomers:", debitCustomers);
    
        let customerResponseId = '';
    
        if (!debitCustomers) {
            // If no customers exist, create a new customer with credit type
            const customerResponse = await createCustomer(currentUser.email, currentUser.name, "credit");
    
            if (!customerResponse.id) {
                throw new Error('Failed to create customer.');
            }
    
            customerResponseId = customerResponse.id;
    
            const paymentResponse = await attachpaymentMethodToCustomer(paymentMethod!.id, customerResponseId);
    
            if (paymentResponse) {
                showToast('Payment details added successfully!', { type: "success" });
            } else {
                showToast(`${paymentResponse.error.message} `, { type: "error" });
                throw new Error('Failed to attach payment method.');
            }
        } else {
            // Check if a credit-type customer already exists
            const directDebitCustomer = debitCustomers.data.find(
                (customer: { metadata: { type: string; }; }) => customer.metadata.type === 'credit'
            );
    
            if (!directDebitCustomer) {
                // If no credit-type customer exists, create one
                const customerResponse = await createCustomer(currentUser.email, currentUser.name, "credit");
    
                if (!customerResponse.id) {
                    throw new Error('Failed to create customer.');
                }
    
                customerResponseId = customerResponse.id;
            } else {
                // Use the existing credit-type customer ID
                customerResponseId = directDebitCustomer.id;
            }
    
            // Attach payment method to the customer
            const paymentResponse = await attachpaymentMethodToCustomer(paymentMethod!.id, customerResponseId);
    
            if (paymentResponse) {
                showToast('Payment details added successfully!', { type: "success" });
            } else {
              showToast(`${paymentResponse.error.message} `, { type: "error" });
                throw new Error('Failed to attach payment method.');
            }
        }
    } catch (error) {
        console.error(error);
        // Display a user-friendly error message
        // showToast(error.message || 'An error occurred. Please try again.', { type: "error" });
    } finally {
      
    onSaveSuccess?.();
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
          cardElement.clear(); // Clear the card element
      }

      setPaymentDetailsValid(false); 
        setLoading(false);
    }
    
  };

  return (
    <form onSubmit={handleSubmit}>
          <Text as="h2" className="text-xl font-bold text-gray-600 mt-0 mb-4">
                        Payment Details
                    </Text>
      <CardElement options={cardElementOptions} onChange={handleCardChange} />
      <Button type="submit" disabled={!stripe && !paymentDetailsValid} className='mt-6'>
      {loading ? 'Processing...' : 'Save Details'}
      </Button>
    </form>
  );
};

export default CreditCardForm;
