
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from './PaymentForm'
import CreditCardForm from './CreditCardForm';

const PUBLIC_KEY = `${process.env.REACT_APP_STRIPE_P_KEY}`;
const stripeTestPromise = loadStripe(PUBLIC_KEY!)
// Define the types for the props
interface PaymentFormProps {
  isRestaurant?: boolean;
  selectedDate: Date | string;
  venue: string;
  phone: string;
  plateNumber: string;
  email: string;
  bookingId: string;
  status: boolean;
  startHour: string;
  endHour: string;
  onSaveSuccess?:() => void;
}

const StripeContainer = ({onSaveSuccess, isRestaurant, selectedDate, venue, phone, plateNumber, email, bookingId, status, startHour, endHour}: PaymentFormProps) => {
  
  return (
    <Elements stripe={stripeTestPromise}>
    {isRestaurant ? (
      <CreditCardForm onSaveSuccess={onSaveSuccess}  />
    ) : (
      <PaymentForm
        isRestaurant={isRestaurant}
        selectedDate={selectedDate}
        venue={venue}
        phone={phone}
        plateNumber={plateNumber}
        email={email}
        bookingId={bookingId}
        status={status}
        startHour={startHour}
        endHour={endHour}
      />
    )}
  </Elements>
  );
};

export default StripeContainer;
