// import { BookingsData, deleteValetBooking } from "../../../utils/apis/bookings_repository";
// import { Text } from "../Text";
// import { Button } from "../CustomButton";
// import { Divider } from "@mui/material";
// import { useEffect, useState } from "react";
// import BookingsDetailsBody from "../../ui/sheets/body/BookingDetailsBody";
// import EditBookingBody from "./body/EditBookingBody";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import { deleteSingleBooking, deleteMultiBooking } from "../../../utils/apis/bookings_repository";
// import { showToast } from "../../../utils/toast";
// import { Dialog, DialogContent } from "../Dialog";
// import { handleOverstayPayment, handleValetCancelBooking } from "../../../utils/apis/payment_repository";
// import { Spinner } from "../Spinner";
// import ValetDetailsBody from "./body/ValetDetailsBody";
// import CreateComplimentaryValetBookingBody from "./CreateComplimentaryValetBooking";

// interface BookingsDetailsProps {
//     booking: BookingsData;
//     setSheetState: (state: boolean) => void;
// }

// const BookingsDetails = ({ booking, setSheetState: setDialogState }: BookingsDetailsProps) => {

//     // use state for changing body of booking
//     const [body, setBody] = useState("details");
//     const queryClient = useQueryClient();

//     const [singleDialog, openSingleDialog] = useState(false);
//     const [multiDialog, openMultiDialog] = useState(false);
//     const [overstayDialog, openOverstayDialog] = useState(false);
//     const [valetComplemenatryDialog, setValetComplimentaryDialog] = useState(false);

//     // create isLoading state for mutation
//     const [isSingleDeleteLoading, setIsSingleDeleteLoading] = useState<boolean>(false);
//     const [isMultiDeleteLoading, setIsMultiDeleteLoading] = useState<boolean>(false);

//     // create isLoading for overstay payment
//     const [isOverstayPaymentLoading, setIsOverstayPaymentLoading] = useState<boolean>(false);
//     const [isOverstayPaymentCharged, setIsOverstayPaymentCharged] = useState<boolean>(false);

//     const updatedBooking: Partial<BookingsData> = {
//         bookingType: booking.bookingType,
//         startTime: booking.startTime,
//         originalBooking: booking.originalBooking,
//         endTime: booking.endTime,
//         status: booking.status,
//         plate: booking.plate,
//         multiID: booking.multiID
//     }

//     const handleValetComplimentarySheetClose = (val: boolean) => {
//         setValetComplimentaryDialog(val);
//         setDialogState(val);
//     }

//     const handleOverstayCharge = async () => {
//         openOverstayDialog(true);
//     }

//     const handleConfirmOverstayCharge = async () => {
//         setIsOverstayPaymentLoading(true);
//         openOverstayDialog(false);
//         const success = await handleOverstayPayment(booking);
//         if (success) {
//             setIsOverstayPaymentCharged(true);
//             setIsOverstayPaymentLoading(false);
//             setTimeout(() => {
//                 setIsOverstayPaymentCharged(false);
//             }, 60000);
//         }
//     }
//     const handleEditClick = () => {
//         setBody("edit");
//     }

//     const handleCancelClick = () => {
//         setBody("details");
//     }


//     const handleValetDetailsClick = () => {
//         setBody("valet");
//     };

//     const deleteSingleBookingMutation = useMutation({
//         mutationFn: deleteSingleBooking,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
//                 setIsSingleDeleteLoading(false);
//                 setDialogState(false);
//             });
//         },
//     })

//     const deleteMultiBookingMutation = useMutation({
//         mutationFn: deleteMultiBooking,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
//                 setIsMultiDeleteLoading(false);
//                 setDialogState(false);
//             });
//         },
//     })

//     const handleSingleDeleteClick = async () => {
//         setIsSingleDeleteLoading(true);
//         openSingleDialog(false);

//         if(booking.bookingType === "Guest" && booking.tenant === "VALET" ){
//             await handleValetCancelBooking(booking.bookingId);
//             setIsSingleDeleteLoading(false);
//             queryClient.invalidateQueries({ queryKey: ['bookings'] });
//             setDialogState(false);
//         }else{
//             deleteSingleBookingMutation.mutate(booking.bookingId);
//         }
//     }

//     const handleSheetClose = (val: boolean) => {
//         setBody("details");
//         setDialogState(val);
//     }

//     const handleMultiDeleteClick = async () => {
//         setIsMultiDeleteLoading(true);
//         openMultiDialog(false);
//         if (booking.multiID) {
//             deleteMultiBookingMutation.mutate(booking.multiID);
//         } else {
//             showToast('Multi ID cannot be determined', { type: 'error' })
//         }
//     }

//     return (
//         <div className="flex flex-col h-screen overflow-hidden items-between mt-12 mx-6">
//             <div className="flex flex-row items-center justify-between">
//                 <Text as="h2"> {body === "details"
//                         ? "Booking Details"
//                         : body === "edit"
//                         ? "Edit Booking"
//                         : "Valet Details"}</Text>
//                 <div className="flex flex-row space-x-4 items-center">
//                     {/* {body === "details" ? <Button variant={"secondary"} onClick={handl
//                     eEditClick} className="w-38 border-primary text-primaryText">Edit Booking</Button> : <Button variant={"secondary"} onClick={handleCancelClick} className="w-38 border-primary text-primaryText">View Details</Button>} */}
//                     {body === "details" ? (
//                         <>
//                             <Button variant={"secondary"} onClick={handleEditClick} className="w-38 border-primary text-primaryText">Edit Booking</Button>
//                             {booking.tenant === "VALET" && booking.paymentId !== 'complimentary' && (
//                                 <Button disabled={isOverstayPaymentCharged} variant={"secondary"} onClick={handleOverstayCharge} className="w-38 border-primary bg-primary text-white">
//                                     {isOverstayPaymentLoading ? <div className="flex items-center">
//                                         <Spinner fill={"white"} />
//                                     </div> : "Pay Overstay Fee"}
//                                 </Button>
//                             )}

//                         </>
//                     ) : (
//                         <Button variant={"secondary"} onClick={handleCancelClick} className="w-38 border-primary text-primaryText">View Details</Button>
//                     )}
//                     <Dialog open={singleDialog} onOpenChange={(val) => openSingleDialog(val)}>
//                         <Button onClick={() => openSingleDialog(true)} isLoading={isSingleDeleteLoading} className="bg-delete w-38">Delete Booking</Button>
//                         <DialogContent>
//                             <Text as="h3" className="text-center">Delete Booking</Text>
//                             <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the user not being able to enter the carpark</Text>
//                             <div className="flex flex-row justify-center space-x-4 mt-6">
//                                 <Button onClick={() => openSingleDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
//                                 <Button onClick={handleSingleDeleteClick} className="w-44 bg-delete text-white">Delete</Button>
//                             </div>
//                         </DialogContent>
//                     </Dialog>
//                     <Dialog open={multiDialog} onOpenChange={(val) => openMultiDialog(val)}>
//                         {booking.bookingType === "Single" ? null : booking.bookingType === "Guest" ? null : <Button isLoading={isMultiDeleteLoading} onClick={() => openMultiDialog(true)} className="bg-delete w-38 text-white">Delete Booking Series</Button>}
//                         <DialogContent>
//                             <Text as="h3" className="text-center">Delete Booking Series</Text>
//                             <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the entire booking series being cancelled</Text>
//                             <div className="flex flex-row justify-center space-x-4 mt-6">
//                                 <Button onClick={() => openMultiDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
//                                 <Button onClick={handleMultiDeleteClick} className="w-44 bg-delete text-white">Delete</Button>
//                             </div>
//                         </DialogContent>
//                     </Dialog>
//                     <Dialog open={overstayDialog} onOpenChange={(val) => openOverstayDialog(val)}>
//                         <DialogContent>
//                             <Text as="h3" className="text-center">Overstay Charge</Text>
//                             {isOverstayPaymentCharged ? (
//                                 <Text as="span" className="text-center">You have already charged for overstaying. Do you want to charge again?</Text>
//                             ) : (
//                                 <Text as="span" className="text-center">Do you want to charge for overstaying?</Text>
//                             )}
//                             <div className="flex flex-row justify-center space-x-4 mt-6">
//                                 <Button onClick={() => openOverstayDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
//                                 <Button onClick={handleConfirmOverstayCharge} className="w-44 bg-primary text-white">Charge</Button>
//                             </div>
//                         </DialogContent>
//                     </Dialog>
//                 </div>
//             </div>
//             <div className="pt-10">
//                 <Divider className="bg-secondaryText" />
//             </div>
//             {body === "details" ? (
//                 <BookingsDetailsBody booking={booking} />
//             ) :
//                 (<EditBookingBody setSheetState={(val) => handleSheetClose(val)} bookingId={booking.bookingId} booking={updatedBooking} />
//         )}</div>
//     );
// }

// export default BookingsDetails;
import { BookingsData, deleteValetBooking } from "../../../utils/apis/bookings_repository";
import { Text } from "../Text";
import { Button } from "../CustomButton";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import BookingsDetailsBody from "../../ui/sheets/body/BookingDetailsBody";
import EditBookingBody from "./body/EditBookingBody";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSingleBooking, deleteMultiBooking } from "../../../utils/apis/bookings_repository";
import { showToast } from "../../../utils/toast";
import { Dialog, DialogContent } from "../Dialog";
import { handleOverstayPayment, handleValetCancelBooking } from "../../../utils/apis/payment_repository";
import { Spinner } from "../Spinner";
import CreateComplimentaryValetBookingBody from "./CreateComplimentaryValetBooking";

interface BookingsDetailsProps {
    booking: BookingsData;
    setSheetState: (state: boolean) => void;
}

const BookingsDetails = ({ booking, setSheetState: setDialogState }: BookingsDetailsProps) => {

    // use state for changing body of booking
    const [body, setBody] = useState("details");
    const queryClient = useQueryClient();

    const [singleDialog, openSingleDialog] = useState(false);
    const [multiDialog, openMultiDialog] = useState(false);
    const [overstayDialog, openOverstayDialog] = useState(false);
    const [valetComplemenatryDialog, setValetComplimentaryDialog] = useState(false);

    // create isLoading state for mutation
    const [isSingleDeleteLoading, setIsSingleDeleteLoading] = useState<boolean>(false);
    const [isMultiDeleteLoading, setIsMultiDeleteLoading] = useState<boolean>(false);

    // create isLoading for overstay payment
    const [isOverstayPaymentLoading, setIsOverstayPaymentLoading] = useState<boolean>(false);
    const [isOverstayPaymentCharged, setIsOverstayPaymentCharged] = useState<boolean>(false);

    const updatedBooking: Partial<BookingsData> = {
        bookingType: booking.bookingType,
        startTime: booking.startTime,
        originalBooking: booking.originalBooking,
        endTime: booking.endTime,
        status: booking.status,
        plate: booking.plate,
        multiID: booking.multiID,
        name: booking.name,
        email: booking.email,
        phone: booking.phone,
        venue: booking.venue
    }

    const handleValetComplimentarySheetClose = (val: boolean) => {
        setValetComplimentaryDialog(val);
        setDialogState(val);
    }

    const handleOverstayCharge = async () => {
        openOverstayDialog(true);
    }

    const handleConfirmOverstayCharge = async () => {
        setIsOverstayPaymentLoading(true);
        openOverstayDialog(false);
        const success = await handleOverstayPayment(booking);
        if (success) {
            setIsOverstayPaymentCharged(true);
            setIsOverstayPaymentLoading(false);
            setTimeout(() => {
                setIsOverstayPaymentCharged(false);
            }, 60000);
        }
    }
    const handleEditClick = () => {
        setBody("edit");
    }

    const handleCancelClick = () => {
        setBody("details");
    }
        const handleValetDetailsClick = () => {
        setBody("valet");
    };
    const deleteSingleBookingMutation = useMutation({
        mutationFn: deleteSingleBooking,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
                setIsSingleDeleteLoading(false);
                setDialogState(false);
            });
        },
    })

    const deleteMultiBookingMutation = useMutation({
        mutationFn: deleteMultiBooking,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
                setIsMultiDeleteLoading(false);
                setDialogState(false);
            });
        },
    })

    const handleSingleDeleteClick = async () => {
        setIsSingleDeleteLoading(true);
        openSingleDialog(false);

        if(booking.bookingType === "Guest" && booking.tenant === "VALET" ){
            await handleValetCancelBooking(booking.bookingId);
            setIsSingleDeleteLoading(false);
            queryClient.invalidateQueries({ queryKey: ['bookings'] });
            setDialogState(false);
        }else{
            deleteSingleBookingMutation.mutate(booking.bookingId);
        }
    }

    const handleSheetClose = (val: boolean) => {
        setBody("details");
        setDialogState(val);
    }

    const handleMultiDeleteClick = async () => {
        setIsMultiDeleteLoading(true);
        openMultiDialog(false);
        if (booking.multiID) {
            deleteMultiBookingMutation.mutate(booking.multiID);
        } else {
            showToast('Multi ID cannot be determined', { type: 'error' })
        }
    }

    return (
        <div className="flex flex-col h-screen overflow-hidden items-between mt-12 mx-6">
            <div className="flex flex-row items-center justify-between">
            <Text as="h2"> {body === "details"
                        ? "Booking Details"
                        : body === "edit"
                        ? "Edit Booking"
                        : "Valet Details"}</Text>                <div className="flex flex-row space-x-4 items-center">
                   {/* {body === "details" ? <Button variant={"secondary"} onClick={handl
                    eEditClick} className="w-38 border-primary text-primaryText">Edit Booking</Button> : <Button variant={"secondary"} onClick={handleCancelClick} className="w-38 border-primary text-primaryText">View Details</Button>} */}
                    {body === "details" ? (
                        <>
                            <Button variant={"secondary"} onClick={handleEditClick} className="w-38 border-primary text-primaryText">Edit Booking</Button>
                            {booking.tenant === "VALET" && booking.paymentId !== 'complimentary' && (
                                <Button disabled={isOverstayPaymentCharged} variant={"secondary"} onClick={handleOverstayCharge} className="w-38 border-primary bg-primary text-white">
                                    {isOverstayPaymentLoading ? <div className="flex items-center">
                                        <Spinner fill={"white"} />
                                    </div> : "Pay Overstay Fee"}
                                </Button>
                            )}

                        </>
                    ) : (
                        <Button variant={"secondary"} onClick={handleCancelClick} className="w-38 border-primary text-primaryText">View Details</Button>
                    )}
                    <Dialog open={singleDialog} onOpenChange={(val) => openSingleDialog(val)}>
                        <Button onClick={() => openSingleDialog(true)} isLoading={isSingleDeleteLoading} className="bg-delete w-38">Delete Booking</Button>
                        <DialogContent>
                            <Text as="h3" className="text-center">Delete Booking</Text>
                            <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the user not being able to enter the carpark</Text>
                            <div className="flex flex-row justify-center space-x-4 mt-6">
                                <Button onClick={() => openSingleDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                                <Button onClick={handleSingleDeleteClick} className="w-44 bg-delete text-white">Delete</Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={multiDialog} onOpenChange={(val) => openMultiDialog(val)}>
                        {booking.bookingType === "Single" ? null : booking.bookingType === "Guest" ? null : <Button isLoading={isMultiDeleteLoading} onClick={() => openMultiDialog(true)} className="bg-delete w-38 text-white">Delete Booking Series</Button>}
                        <DialogContent>
                            <Text as="h3" className="text-center">Delete Booking Series</Text>
                            <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the entire booking series being cancelled</Text>
                            <div className="flex flex-row justify-center space-x-4 mt-6">
                                <Button onClick={() => openMultiDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                                <Button onClick={handleMultiDeleteClick} className="w-44 bg-delete text-white">Delete</Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={overstayDialog} onOpenChange={(val) => openOverstayDialog(val)}>
                        <DialogContent>
                            <Text as="h3" className="text-center">Overstay Charge</Text>
                            {isOverstayPaymentCharged ? (
                                <Text as="span" className="text-center">You have already charged for overstaying. Do you want to charge again?</Text>
                            ) : (
                                <Text as="span" className="text-center">Do you want to charge for overstaying?</Text>
                            )}
                            <div className="flex flex-row justify-center space-x-4 mt-6">
                                <Button onClick={() => openOverstayDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                                <Button onClick={handleConfirmOverstayCharge} className="w-44 bg-primary text-white">Charge</Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
            <div className="pt-10">
                <Divider className="bg-secondaryText" />
            </div>
            {body === "details" ? <BookingsDetailsBody booking={booking} /> : booking.paymentId === "complimentary" ? <CreateComplimentaryValetBookingBody setDialogState={handleValetComplimentarySheetClose} editData={booking}/> : <EditBookingBody setSheetState={(val) => handleSheetClose(val)} bookingId={booking.bookingId} booking={updatedBooking} />}
        </div>
    );
}

export default BookingsDetails;
// import { BookingsData, deleteValetBooking } from "../../../utils/apis/bookings_repository";
// import { Text } from "../Text";
// import { Button } from "../CustomButton";
// import { Divider } from "@mui/material";
// import { useEffect, useState } from "react";
// import BookingsDetailsBody from "../../ui/sheets/body/BookingDetailsBody";
// import EditBookingBody from "./body/EditBookingBody";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import { deleteSingleBooking, deleteMultiBooking } from "../../../utils/apis/bookings_repository";
// import { showToast } from "../../../utils/toast";
// import { Dialog, DialogContent } from "../Dialog";
// import { handleOverstayPayment, handleValetCancelBooking } from "../../../utils/apis/payment_repository";
// import { Spinner } from "../Spinner";
// import CreateComplimentaryValetBookingBody from "./CreateComplimentaryValetBooking";
// import ValetDetailsBody from "./body/ValetDetailsBody";

// interface BookingsDetailsProps {
//     booking: BookingsData;
//     setSheetState: (state: boolean) => void;
// }

// const BookingsDetails = ({ booking, setSheetState: setDialogState }: BookingsDetailsProps) => {

//     // use state for changing body of booking
//     const [body, setBody] = useState("details");
//     const queryClient = useQueryClient();

//     const [singleDialog, openSingleDialog] = useState(false);
//     const [multiDialog, openMultiDialog] = useState(false);
//     const [overstayDialog, openOverstayDialog] = useState(false);
//     const [valetComplemenatryDialog, setValetComplimentaryDialog] = useState(false);

//     // create isLoading state for mutation
//     const [isSingleDeleteLoading, setIsSingleDeleteLoading] = useState<boolean>(false);
//     const [isMultiDeleteLoading, setIsMultiDeleteLoading] = useState<boolean>(false);

//     // create isLoading for overstay payment
//     const [isOverstayPaymentLoading, setIsOverstayPaymentLoading] = useState<boolean>(false);
//     const [isOverstayPaymentCharged, setIsOverstayPaymentCharged] = useState<boolean>(false);

//     const updatedBooking: Partial<BookingsData> = {
//         bookingType: booking.bookingType,
//         startTime: booking.startTime,
//         originalBooking: booking.originalBooking,
//         endTime: booking.endTime,
//         status: booking.status,
//         plate: booking.plate,
//         multiID: booking.multiID
//     }

//     const handleValetComplimentarySheetClose = (val: boolean) => {
//         setValetComplimentaryDialog(val);
//         setDialogState(val);
//     }

//     const handleOverstayCharge = async () => {
//         openOverstayDialog(true);
//     }

//     const handleConfirmOverstayCharge = async () => {
//         setIsOverstayPaymentLoading(true);
//         openOverstayDialog(false);
//         const success = await handleOverstayPayment(booking);
//         if (success) {
//             setIsOverstayPaymentCharged(true);
//             setIsOverstayPaymentLoading(false);
//             setTimeout(() => {
//                 setIsOverstayPaymentCharged(false);
//             }, 60000);
//         }
//     }
//     const handleEditClick = () => {
//         setBody("edit");
//     }

//     const handleCancelClick = () => {
//         setBody("details");
//     }


//     const handleValetDetailsClick = () => {
//         setBody("valet");
//     };

//     const deleteSingleBookingMutation = useMutation({
//         mutationFn: deleteSingleBooking,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
//                 setIsSingleDeleteLoading(false);
//                 setDialogState(false);
//             });
//         },
//     })

//     const deleteMultiBookingMutation = useMutation({
//         mutationFn: deleteMultiBooking,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
//                 setIsMultiDeleteLoading(false);
//                 setDialogState(false);
//             });
//         },
//     })

//     const handleSingleDeleteClick = async () => {
//         setIsSingleDeleteLoading(true);
//         openSingleDialog(false);

//         if(booking.bookingType === "Guest" && booking.tenant === "VALET" ){
//             await handleValetCancelBooking(booking.bookingId);
//             setIsSingleDeleteLoading(false);
//             queryClient.invalidateQueries({ queryKey: ['bookings'] });
//             setDialogState(false);
//         }else{
//             deleteSingleBookingMutation.mutate(booking.bookingId);
//         }
//     }

//     const handleSheetClose = (val: boolean) => {
//         setBody("details");
//         setDialogState(val);
//     }

//     const handleMultiDeleteClick = async () => {
//         setIsMultiDeleteLoading(true);
//         openMultiDialog(false);
//         if (booking.multiID) {
//             deleteMultiBookingMutation.mutate(booking.multiID);
//         } else {
//             showToast('Multi ID cannot be determined', { type: 'error' })
//         }
//     }

//     return (
//         <div className="flex flex-col h-screen overflow-hidden items-between mt-12 mx-6">
//             <div className="flex flex-row items-center justify-between">
//                 <Text as="h2"> {body === "details"
//                         ? "Booking Details"
//                         : body === "edit"
//                         ? "Edit Booking"
//                         : "Valet Details"}</Text>
//                 <div className="flex flex-row space-x-4 items-center">
//                     {/* {body === "details" ? <Button variant={"secondary"} onClick={handl
//                     eEditClick} className="w-38 border-primary text-primaryText">Edit Booking</Button> : <Button variant={"secondary"} onClick={handleCancelClick} className="w-38 border-primary text-primaryText">View Details</Button>} */}
//                     {body === "details" ? (
//                         <>
//                             <Button variant={"secondary"} onClick={handleEditClick} className="w-38 border-primary text-primaryText">Edit Booking</Button>
//                             {booking.tenant === "VALET" && booking.paymentId !== 'complimentary' && (
//                                 <Button disabled={isOverstayPaymentCharged} variant={"secondary"} onClick={handleOverstayCharge} className="w-38 border-primary bg-primary text-white">
//                                     {isOverstayPaymentLoading ? <div className="flex items-center">
//                                         <Spinner fill={"white"} />
//                                     </div> : "Pay Overstay Fee"}
//                                 </Button>
//                             )}

//                         </>
//                     ) : (
//                         <Button variant={"secondary"} onClick={handleCancelClick} className="w-38 border-primary text-primaryText">View Details</Button>
//                     )}
//                     <Dialog open={singleDialog} onOpenChange={(val) => openSingleDialog(val)}>
//                         <Button onClick={() => openSingleDialog(true)} isLoading={isSingleDeleteLoading} className="bg-delete w-38">Delete Booking</Button>
//                         <DialogContent>
//                             <Text as="h3" className="text-center">Delete Booking</Text>
//                             <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the user not being able to enter the carpark</Text>
//                             <div className="flex flex-row justify-center space-x-4 mt-6">
//                                 <Button onClick={() => openSingleDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
//                                 <Button onClick={handleSingleDeleteClick} className="w-44 bg-delete text-white">Delete</Button>
//                             </div>
//                         </DialogContent>
//                     </Dialog>
//                     <Dialog open={multiDialog} onOpenChange={(val) => openMultiDialog(val)}>
//                         {booking.bookingType === "Single" ? null : booking.bookingType === "Guest" ? null : <Button isLoading={isMultiDeleteLoading} onClick={() => openMultiDialog(true)} className="bg-delete w-38 text-white">Delete Booking Series</Button>}
//                         <DialogContent>
//                             <Text as="h3" className="text-center">Delete Booking Series</Text>
//                             <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the entire booking series being cancelled</Text>
//                             <div className="flex flex-row justify-center space-x-4 mt-6">
//                                 <Button onClick={() => openMultiDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
//                                 <Button onClick={handleMultiDeleteClick} className="w-44 bg-delete text-white">Delete</Button>
//                             </div>
//                         </DialogContent>
//                     </Dialog>
//                     <Dialog open={overstayDialog} onOpenChange={(val) => openOverstayDialog(val)}>
//                         <DialogContent>
//                             <Text as="h3" className="text-center">Overstay Charge</Text>
//                             {isOverstayPaymentCharged ? (
//                                 <Text as="span" className="text-center">You have already charged for overstaying. Do you want to charge again?</Text>
//                             ) : (
//                                 <Text as="span" className="text-center">Do you want to charge for overstaying?</Text>
//                             )}
//                             <div className="flex flex-row justify-center space-x-4 mt-6">
//                                 <Button onClick={() => openOverstayDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
//                                 <Button onClick={handleConfirmOverstayCharge} className="w-44 bg-primary text-white">Charge</Button>
//                             </div>
//                         </DialogContent>
//                     </Dialog>
//                 </div>
//             </div>
//             <div className="pt-10">
//                 <Divider className="bg-secondaryText" />
//             </div>
//             {body === "details" ?
//                 <BookingsDetailsBody booking={booking} /> : booking.paymentId === "complimentary" ? <CreateComplimentaryValetBookingBody setDialogState={handleValetComplimentarySheetClose} editData={booking}/>
//              :
//                 (<EditBookingBody setSheetState={(val) => handleSheetClose(val)} bookingId={booking.bookingId} booking={updatedBooking} />
//         )}</div>
//     );
// }

// export default BookingsDetails;
