import React, { useState, useEffect } from 'react';
import { addPaymentDetails, createCustomer, getAllCustomers } from '../../../utils/apis/payment_repository'; // Custom API calls for your backend
import { Button } from "../../../components/ui/CustomButton"; // Custom button component
// import { FaBank } from 'react-icons/fa'; // Example for bank icon
import { getUserFromLocalStorage } from '../../../utils/helper';
import { showToast } from '../../../utils/toast';
interface DirectDebitProps {
  onSaveSuccess?:() => void;
}
const DirectDebitForm = ({onSaveSuccess}: DirectDebitProps) => {
  const currentUser = getUserFromLocalStorage(); // Assuming this fetches the current user
  const [bsb, setBsb] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Handle form submission
  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');

    // Create a customer using the provided details
    try {
      const debitCustomers = await getAllCustomers(currentUser.email);
  
      let customerResponseId = '';
  
      if (!debitCustomers) {
          // If no customers exist, create a new customer with debit type
          const customerResponse = await createCustomer(currentUser.email, fullName, "debit");
  
          if (!customerResponse.id) {
              throw new Error('Failed to create customer.');
          }
  
          customerResponseId = customerResponse.id;
  
          const paymentResponse = await addPaymentDetails(customerResponseId, bsb, accountNumber, fullName, currentUser.email);
  
          if (paymentResponse) {
              showToast('Payment details added successfully!', { type: "success" });
          } else {
              setErrorMessage('Failed to add payment details.');
          }
      } else {
          // Check if a debit-type customer already exists
          const directDebitCustomer = debitCustomers.data.find(
              (customer: { metadata: { type: string; }; }) => customer.metadata.type === 'debit'
          );
  
          if (directDebitCustomer) {
              customerResponseId = directDebitCustomer.id;
          } else {
            const customerResponse = await createCustomer(currentUser.email, fullName, "debit");
  
            if (!customerResponse.id) {
                throw new Error('Failed to create customer.');
            }

            customerResponseId = customerResponse.id
          }
  
          // Add payment details to the customer
          const paymentResponse = await addPaymentDetails(customerResponseId, bsb, accountNumber, fullName, currentUser.email );
  
          if (paymentResponse) {
              showToast('Payment details added successfully!', { type: "success" });
          } else {
              setErrorMessage('Failed to add payment details.');
          }

          
      }
  } catch (error) {
      console.error(error);
      // Display a user-friendly error message
      // showToast(error.message || 'An error occurred. Please try again.', { type: "error" });
  } finally {
    onSaveSuccess?.();
    setBsb('');
        setAccountNumber('');
        setFullName('');
      setLoading(false);
  }
  
  };

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-lg ">
   
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="bsb" className="text-sm font-medium text-gray-700">BSB</label>
          <input
            type="text"
            id="bsb"
            value={bsb}
            onChange={(e) => setBsb(e.target.value)}
            placeholder="BSB"
            className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="accountNumber" className="text-sm font-medium text-gray-700">Account Number</label>
          <input
            type="text"
            id="accountNumber"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            placeholder="Account number"
            className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="fullName" className="text-sm font-medium text-gray-700">Full Name</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full name"
            className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="text-sm text-gray-600 mt-2">
          <p>
            By providing your bank account details and confirming this payment, you agree to the Direct Debit Request and the Direct Debit Request service agreement and authorize Stripe Payments Australia Pty Ltd to debit your account.
          </p>
        </div>

        <Button
          type="submit"
          disabled={loading}
          className="w-full py-3 mt-6 text-white bg-primary rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {loading ? 'Processing...' : 'Save Details'}
        </Button>

        {errorMessage && <div className="text-red-500 text-center mt-4">{errorMessage}</div>}
      </form>
    </div>
  );
};

export default DirectDebitForm;
