import axios from 'axios';
import { addOverstayEventToBooking, deleteValetBooking, getValetBookingDetails } from './bookings_repository';
import { showToast } from '../toast';
import log from '../logger';
import { PaymentMethod } from '@stripe/stripe-js';

const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
const stripeApiUrl = process.env.REACT_APP_STRIPE_API_URL;

export const createCustomer = async ( email: string, phone: string, paymentType: string) => {
    const customer = await axios.post(
        `${stripeApiUrl}/customers`,
        {
            // payment_method: paymentMethodId,
            email,
            phone,
            metadata: {
                type: paymentType
            }
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${stripeSecretKey}`,
            },
        }
    ).then(res => res.data);

    return customer;
};
export const createStripeCustomer = async (paymentMethodId: string |PaymentMethod  | null, email: string, phone: string) => {
    const customer = await axios.post(
        `${stripeApiUrl}/customers`,
        {
            payment_method: paymentMethodId,
            email,
            phone,
            invoice_settings: {
                default_payment_method: paymentMethodId,
            }
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${stripeSecretKey}`,
            },
        }
    ).then(res => res.data);

    return customer;
};

export const attachpaymentMethodToCustomer = async (paymentMethodId: string, customerId: string) => {
    try {
        // Step 2: Attach the payment method to the customer
        await axios.post(
            `${stripeApiUrl}/payment_methods/${paymentMethodId}/attach`,
            { customer: customerId },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        );

        // Step 3: Update the customer to set the payment method as default
        const updatedCustomer = await axios
            .post(
                `${stripeApiUrl}/customers/${customerId}`,
                {
                    invoice_settings: {
                        default_payment_method: paymentMethodId,
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `Bearer ${stripeSecretKey}`,
                    },
                }
            )
            .then((res) => res.data);

        // Return the updated customer if successful
        return updatedCustomer;
    } catch (error: any) {
        if (error.response && error.response.data && error.response.data.error) {
            const stripeError = error.response.data.error;

            console.error('Stripe Error:', stripeError);
            showToast(`${stripeError.message}`, {type:"error"})
            // if (stripeError.code === 'expired_card') {
            //     showToast(`${stripeError.message}`, {type:"error"})
            //     throw new Error('Your card has expired. Please use a valid card.');
            // } else if (stripeError.type === 'card_error') {
            //     throw new Error(`Card error: ${stripeError.message}`);
            // } else {
            //     throw new Error(`Error: ${stripeError.message}`);
            // }
        } else {
            // Catch unexpected errors
            console.error('Unexpected Error:', error.message);
            throw new Error('An unexpected error occurred. Please try again later.');
        }
    }
};


//used
export const addPaymentDetails = async (customerId: any, bsb: any, accountNumber: any, fullName: any, email: any) => {
    try {
        // Step 1: Create the customer
        const response = await axios.post(
            `${stripeApiUrl}/payment_methods`,
            {
                type: 'au_becs_debit',
                au_becs_debit: { bsb_number: bsb , account_number: accountNumber  }, 
                billing_details: {
                  name: fullName,
                  email: email
                },
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        ).then((res) => res.data);

        // Step 2: Attach the payment method to the customer
        await axios.post(
            `${stripeApiUrl}/payment_methods/${response.id}/attach`,
            {
                customer: customerId,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        );

        // Step 3: Update the customer to set the payment method as default
        const updatedCustomer = await axios.post(
            `${stripeApiUrl}/customers/${customerId}`,
            {
                invoice_settings: {
                    default_payment_method: response.id,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        ).then((res) => res.data);

        return updatedCustomer;
    } catch (error) {
        // console.error('Error creating customer or attaching payment method:', error.response?.data || error.message);
        // throw new Error(error.response?.data?.error?.message || 'Failed to create customer or attach payment method.');
    }
};
export const addPaymentDetailsToCredit = async (customerId: any, number: string, expMonth: string, expYear: string,cvc: string, name: string,email: string) => {
    try {
        // Step 1: Create the payment method for a credit card
        const response = await axios.post(
            `${stripeApiUrl}/payment_methods`,
            {
                type: 'card',
                card: {
                    number: number,
                    exp_month: expMonth,
                    exp_year: `20${expYear}`,
                    cvc: cvc,
                },
                billing_details: {
                  name: name,
                  email: email,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        ).then((res) => res.data);

        // Step 2: Attach the payment method to the customer
        await axios.post(
            `${stripeApiUrl}/payment_methods/${response.id}/attach`,
            {
                customer: customerId,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        );

        // Step 3: Update the customer to set the payment method as default
        const updatedCustomer = await axios.post(
            `${stripeApiUrl}/customers/${customerId}`,
            {
                invoice_settings: {
                    default_payment_method: response.id,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        ).then((res) => res.data);

        return updatedCustomer;
    } catch (error) {
        // console.error('Error creating payment method or attaching it to the customer:', error.response?.data || error.message);
        // throw new Error(error.response?.data?.error?.message || 'Failed to create payment method or attach it to the customer.');
    }
};

export const removePaymentDetails = async (paymentId: string) => {
    try {
        // Step 1: Create the customer
        const response = await axios.post(
            `${stripeApiUrl}/payment_methods/${paymentId}/detach`,
            {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        ).then((res) => res.data);

        return response;
    } catch (error) {
        // console.error('Error creating customer or attaching payment method:', error.response?.data || error.message);
        // throw new Error(error.response?.data?.error?.message || 'Failed to create customer or attach payment method.');
    }
};

export const createDebitStripeCustomer = async (
    id: string,
    paymentMethodId: string | PaymentMethod | null,
    email: string,
    phone: string
) => {
    try {
        // Step 1: Create the customer
        const customer = await axios.post(
            `${stripeApiUrl}/customers`,
            {
                email,
                phone,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        ).then((res) => res.data);

        // Step 2: Attach the payment method to the customer
        await axios.post(
            `${stripeApiUrl}/payment_methods/${paymentMethodId}/attach`,
            {
                customer: customer.id,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        );

        // Step 3: Update the customer to set the payment method as default
        const updatedCustomer = await axios.post(
            `${stripeApiUrl}/customers/${customer.id}`,
            {
                invoice_settings: {
                    default_payment_method: paymentMethodId,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${stripeSecretKey}`,
                },
            }
        ).then((res) => res.data);

        return updatedCustomer;
    } catch (error) {
        // console.error('Error creating customer or attaching payment method:', error.response?.data || error.message);
        // throw new Error(error.response?.data?.error?.message || 'Failed to create customer or attach payment method.');
    }
};
// export const createDebitStripeCustomer = async (id: string,paymentMethodId: string |PaymentMethod  | null, email: string, phone: string) => {
//     const customer = await axios.post(
//         `${stripeApiUrl}/customers`,
//         {
//             // payment_method: paymentMethodId,
//             email,
//             phone,
//             // payment_method: paymentMethodId, // Attach the payment method to the customer
//             invoice_settings: {
//                 default_payment_method: paymentMethodId, // Optional: Set it as default for future usage
//             },
//             // setup_future_usage: paymentMethodId
//         },
//         {
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded',
//                 'Authorization': `Bearer ${stripeSecretKey}`,
//             },
//         }
//     ).then(res => res.data);

//     return customer;
// };
export const createPaymentIntent = async (
    customerId: string,
    amount: number,
    paymentMethodId: string,
    description: string
) => {
    const paymentIntent = await axios.post(
        `${stripeApiUrl}/payment_intents`,
        {
            customer: customerId,
            amount: Math.round(amount * 100), // Amount in cents
            currency: 'aud',
            payment_method: paymentMethodId,
            off_session: true,
            confirm: true,
            description,
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${stripeSecretKey}`,
            },
        }
    ).then(res => res.data);

    return paymentIntent;
};
export const createSetupIntent = async (customerId: any) => {
    try {
        const setupIntent = await axios.post(
            `${stripeApiUrl}/setup_intents`,
            {
                customer: customerId,
                // payment_method: paymentMethodId,
                payment_method_types: ['au_becs_debit'], // Specify the method type relevant for BECS
                // description,
                usage: 'off_session', // Indicate that the payment method will be used later
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        return setupIntent;
    } catch (error) {
        console.error('Error creating setup intent:', error);
        throw new Error('Failed to create setup intent');
    }
};
export const createPaymentMethod = async (accountHolderType: any, accountNumber: any, routingNumber: any, name: any) => {
    try {
        const paymentMethod = await axios.post(
            `${stripeApiUrl}/payment_methods`,
            new URLSearchParams({
                type: 'au_becs_debit',
                "au_becs_debit[account_holder_type]": 'individual', // e.g., 'individual' or 'company'
                "au_becs_debit[account_number]": '000123456', // e.g., '000123456'
                "au_becs_debit[routing_number]": '000000', // e.g., '110000'
                "billing_details[name]": name, // e.g., 'John Doe'
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        return paymentMethod;
    } catch (error) {
        // console.error('Error creating payment method:', error.response?.data || error.message);
        throw new Error('Failed to create payment method');
    }
};
export const handleOverstayPayment = async (bookingsData: any): Promise<boolean> => {
    const bookingData = await getValetBookingDetails(bookingsData.bookingId);
    const { customerId, plateNumber, venue, selectedDate } = bookingData;

    try {
        // Fetch customer details from Stripe
        const customer = await axios.post(
            `${stripeApiUrl}/customers/${customerId}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        // Create payment intent for overstay fee
        const overstayPayment = await axios.post(
            'https://api.stripe.com/v1/payment_intents',
            {
                amount: 150 * 100, // Overstay fee in cents
                currency: 'aud',
                customer: customerId,
                payment_method: customer.invoice_settings.default_payment_method,
                off_session: true,
                confirm: true,
                description: `Overstay fee for vehicle ${plateNumber} at ${venue} on ${selectedDate}`,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        if (overstayPayment && overstayPayment.status === 'succeeded') {
            console.log("Overstay charge successful:", overstayPayment);
            await addOverstayEventToBooking(bookingData.bookingId);
            log({collection: 'bookings', documentId: bookingData.bookingId, message: 'Overstay charged', type: 'update'});
            showToast('Overstay charge successful', { type: 'success' });
            return true;
        } else {
            console.error("Overstay charge failed:", overstayPayment);
            showToast('Overstay charge failed', { type: 'error' });
            return false;
        }
    } catch (error) {
        console.error("Error processing overstay payment:", error);
        showToast('Error processing overstay payment', { type: 'error' });
        return false;
    }
};
export const handleExistingPayments = async (customerId: string, amount: number, descripton: string): Promise<string> => {
    try {
        // Fetch customer details from Stripe
        const customer = await axios.post(
            `${stripeApiUrl}/customers/${customerId}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        // Create payment intent for overstay fee
        const paymentDetails = await axios.post(
            'https://api.stripe.com/v1/payment_intents',
            {
                amount: Math.round(amount * 100), 
                currency: 'aud',
                customer: customerId,
                payment_method: customer.invoice_settings.default_payment_method,
                off_session: true,
                confirm: true,
                description: descripton,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        if (paymentDetails && paymentDetails.status === 'succeeded') {
            // showToast(' charge successful', { type: 'success' });
            return paymentDetails.id;
        } else {
            showToast('charge failed', { type: 'error' });
            return '';
        }
    } catch (error) {
        console.error("Error processing payment:", error);
        showToast('Error processing payment', { type: 'error' });
        return '';
    }
};
export const handleValetCancelBooking = async (bookingId: any): Promise<boolean> => {
    try {
        const bookingData = await getValetBookingDetails(bookingId);
        const { paymentId } = bookingData;

        if (!paymentId || paymentId === 'complimentary' || paymentId === 'freeCoupon') {
            await deleteValetBooking(bookingId);
            log({collection: 'bookings', documentId: bookingId, message: 'Valet booking canceled', type: 'delete'});
            showToast('Booking canceled successfully', { type: 'success' });
            return true;
        }

        // Process the refund
        const { data } = await axios.post(
            `${stripeApiUrl}/refunds`,
            {
                payment_intent: paymentId,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        );

        if (data.status === 'succeeded') {
            await deleteValetBooking(bookingId);
            log({collection: 'bookings', documentId: bookingId, message: 'Valet booking canceled', type: 'delete'});
            showToast('Booking canceled successfully', { type: 'success' });
            return true;
        } else {
            console.error("Refund failed:", data);
            showToast('Refund failed', { type: 'error' });
            return false;
        }
    } catch (error) {
        console.error('Error canceling booking:', error);
        showToast('Error canceling booking', { type: 'error' });
        return false;
    }
};

export async function verifyRecaptchaFrontend(recaptchaToken: string) {
    const SECRET_KEY_V3 = "6LdE_icqAAAAAJL-8Awpz9f2cIucEkTD6C5vwyLb";
    const url = `https://www.google.com/recaptcha/api/siteverify?secret=${SECRET_KEY_V3}&response=${recaptchaToken}`;

    try {
        const response = await axios.get(url, {
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error verifying reCAPTCHA:", error);
        throw error;
    }
}

export const fetchPaymentDetailsByEmail = async (email: string): Promise<any> => {
    try {
        // Fetch the list of customers and filter by email
        const { data: customersData } = await axios.get(
            `${stripeApiUrl}/customers?email=${encodeURIComponent(email)}`,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        );

        if (customersData.data.length === 0) {
            console.log("No customers found for the provided email.");
            // showToast('No payment details found for this email', { type: 'info' });
            return null;
        }

        const creditCardCustomer = customersData.data.find((customer: { metadata: { type: string; }; }) => customer.metadata.type === 'credit');


        // Fetch the payment methods for the found customer
        const { data: paymentMethodsData } = await axios.get(
            `${stripeApiUrl}/payment_methods?customer=${creditCardCustomer.id}&type=card`,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        );

        console.log("Payment methods for the customer:", paymentMethodsData);
        return {
            id: creditCardCustomer.id,
            paymentMethods: paymentMethodsData.data,
        };
    } catch (error) {
        console.error("Error fetching payment details by email:", error);
        // showToast('Error fetching payment details', { type: 'error' });
        throw error;
    }
};
export const getAllCustomers = async (email: string) : Promise<any> =>{
    const { data: customersData } = await axios.get(
        `${stripeApiUrl}/customers?email=${encodeURIComponent(email)}`,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${stripeSecretKey}`,
            },
        }
    );

    if (customersData.data.length === 0) {
        console.log("No customers found for the provided email.");
        // showToast('No customer details found for this email', { type: 'info' });
        return null;
    }

    return customersData
};
export const fetchDirectDebitDetailsByEmail = async (email: string): Promise<any> => {
    try {
        // Fetch the customer by email
        const { data: customersData } = await axios.get(
            `${stripeApiUrl}/customers?email=${encodeURIComponent(email)}`,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        );

        if (customersData.data.length === 0) {
            console.log("No customers found for the provided email.");
            // showToast('No customer details found for this email', { type: 'info' });
            return null;
        }
        // const customer = customersData.data[0]; // Assuming the first customer is the relevant one
        const directDebitCustomer = customersData.data.find((customer: { metadata: { type: string; }; }) => customer.metadata.type === 'debit');

        // Fetch the direct debit payment methods for the found customer
        // Adjust 'type' parameter as per your Stripe setup for direct debits
        const { data: paymentMethodsData } = await axios.get(
            `${stripeApiUrl}/payment_methods?customer=${directDebitCustomer.id}&type=au_becs_debit`, // Example type
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        );

        console.log("Direct debit methods for the customer:", directDebitCustomer);
        return {
            id: directDebitCustomer.id,
            paymentMethods: paymentMethodsData.data,
        };
    } catch (error) {
        console.error("Error fetching direct debit details by email:", error);
        // showToast('Error fetching direct debit details', { type: 'error' });
        throw error;
    }
};
