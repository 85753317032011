import { Navigate, Outlet } from "react-router-dom";

function RequireAuth() {
  const user = JSON.parse(localStorage.getItem("user")?.toString() || "{}");
  // console.log("user in auth check: ", user);

  if (!user || !user.email || !user.role || Object.keys(user).length === 0) {
    return <Navigate to="/signin" />;
  } else if (user.role !== "SuperAdmin" && user.role !== "TenantAdmin" && user.role !== "RestaurantUser") {
    return <Navigate to="/unauth" />;
  }

  return <Outlet/>;
}

export default RequireAuth
