import { BookingsData, editMultiBooking, editSingleBooking, updateValetBooking } from "../../../../utils/apis/bookings_repository";
import { Text } from "../../Text";
import { DatePicker } from "../../inputs/DatePicker";
import { getDateObject, getTime, addDays, getDateTimeString } from "../../../../utils/helper";
import { useState } from "react";
import { Button } from "../../CustomButton";
import { TimePicker } from "../../inputs/TimePicker";
import { DatePickerWithRange } from "../../inputs/DateRangePicker";
import { SheetClose } from "../../Sheets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showToast } from "../../../../utils/toast";
import { DateTime } from "luxon";
import React from "react";
import axios from "axios";

interface EditBookingBodyProps {
    booking: Partial<BookingsData>;
    bookingId: string
    setSheetState: (state: boolean) => void;
}

const EditBookingBody = ({ booking, bookingId, setSheetState }: EditBookingBodyProps) => {

    const queryClient = useQueryClient();

    const [bookingType, setBookingType] = useState(booking.bookingType);
    const [startTime, updateStartTime] = useState<string>(getTime(booking.startTime!));
    const [endTime, updateEndTime] = useState<string>(getTime(booking.endTime!));

    const [isLoading, setIsLoading] = useState(false);
    const [retailUserName, setRetailUserName] = useState(booking.name);
    const [retailUserEmail, setRetailUserEmail] = useState(booking.email);
    const [retailUserPhone, setRetailUserPhone] = useState(booking.phone);
    const [retailUserVenue, setRetailUserVenue] = useState(booking.venue);
    const [retailUserPlate, setRetailUserPlate] = useState(booking.plate);
    const [startDate, setStartDate] = useState<Date>(getDateObject(booking.startTime!));
    const [endDate, setEndDate] = useState<Date>(
        booking.bookingType === "Single" ? getDateObject(booking.endTime!) : getDateObject(booking.originalBooking!.endTime)
    );

    const handleClickMulti = () => {
        setBookingType("Multi");
    }

    const handleClickSingle = () => {
        setBookingType("Single");
    }

    const handleStartTime = (time: string) => {
        updateStartTime(time);
    }

    const handleEndTime = (time: string): void => {
        updateEndTime(time);
    }

    const handleStartDate = (date: Date) => {
        setStartDate(date);
    }

    const handleEndDate = (date: Date) => {
        setEndDate(date);
    }


    const singleBookingMutation = useMutation({
        mutationFn: editSingleBooking,
        onSuccess: (val) => {
            console.log("Loading state", isLoading)
            if (val) {
                console.log("<<== single booking updated successfully ==>>");
                queryClient.invalidateQueries({ queryKey: ['bookings'] });
                setSheetState(false);
            } else {
                setTimeout(() => {
                    console.log("<<== booking update failed ==>>");
                    queryClient.invalidateQueries({ queryKey: ['bookings'] });
                    setSheetState(false);
                }, 2000);
            }
        },
    })

    const multiBookingMutation = useMutation({
        mutationFn: editMultiBooking,
        onSuccess: () => {
            console.log("<<== multi booking updated successfully ==>>");
            queryClient.invalidateQueries({ queryKey: ['bookings'] });
            setSheetState(false);
        },
    })

    const handleBookingUpdate = async () => {

        const now = DateTime.now();

        if ((!startDate || !endDate) && bookingType === "Multi") {
            showToast("One or more date fields are empty", {
                type: "warning"
            });
            return;
        }

        if (!startDate && bookingType === "Single") {
            showToast("Start date is empty", {
                type: "warning"
            });
            return;
        }

        if (!startTime) {
            showToast("Start time is empty", {
                type: "warning"
            });
            return;
        }

        if (!endTime) {
            showToast("End time is empty", {
                type: "warning"
            });
            return;
        }


        const updatedStartDate = getDateTimeString(startDate.toISOString(), startTime);
        const updatedEndDate = getDateTimeString(bookingType === "Single" ? startDate.toISOString() : endDate.toISOString(), endTime);

        if(DateTime.fromJSDate(new Date(updatedStartDate)) < now) {
            showToast("Start time cannot be in the past", {
                type: "warning"
            });
            return;
        }

        if(DateTime.fromJSDate(new Date(updatedStartDate)) > DateTime.fromJSDate(new Date(updatedEndDate))) {
            showToast("Start date cannot be after than the end date", {
                type: "warning"
            });
            return;
        }

        setIsLoading(true);

        if(bookingType === "Single"){
            const updatedBooking: Partial<BookingsData> = {
                bookingId: bookingId,
                startTime: updatedStartDate,
                endTime: updatedEndDate,
            }
            singleBookingMutation.mutate(updatedBooking);
        }else if(bookingType === "Multi"){
            const updatedBooking: Partial<BookingsData> = {
                multiID: booking.multiID,
                startTime: updatedStartDate,
                endTime: updatedEndDate,
            }
            multiBookingMutation.mutate(updatedBooking);
        }else if(bookingType === "Guest"){

        const bookingData = {
            startTime: startDate.toISOString(),
            venue: retailUserVenue,
            email: retailUserEmail,
            plateNumber: retailUserPlate!.toString(),
            bookingId: bookingId,
            phone: retailUserPhone,
            name: retailUserName,
        };

        try {
            const success = await updateValetBooking(bookingData);
            if (success) {
                setIsLoading(true); // Set edit success state
                queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
                    setSheetState(false);
                });
            }
            // setSavingChanges(false);
        } catch (error) {
            console.error('Error saving booking changes:', error);
            // setSavingChanges(false);
        }
        }
    }
    const handleDateChange = async (date: Date) => {
        setStartDate(date);

    };
    return (
        <>
                     {bookingType === "Guest" && (

              <><div className="scrollable-content mt-1" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <Text as="h3">Booking Type</Text>
                    <div className="flex flex-row space-x-4 mt-6">
                        <Button onClick={handleClickSingle} variant={bookingType === "Guest" ? "default" : "secondary"} className={`w-44 border-primary ${bookingType === "Guest" ? "text-white" : "border-primary text-primaryText"}`}>Valet Booking</Button>
                    </div>

                    <Text className="mt-10 mb-5" as="h3">Booking Date</Text>
                    <DatePicker setNewDate={(value) => handleDateChange(value)} initialDate={startDate} />
                    <Text as="h3">Retail User Details</Text>
                    <div className="flex flex-col gap-4 mt-6">
                        <input
                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            type="text"
                            placeholder="Name*"
                            value={retailUserName}
                            onChange={(e) => setRetailUserName(e.target.value)}
                            required />
                        <input
                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            type="text"
                            placeholder="Email*"
                            value={retailUserEmail}
                            onChange={(e) => setRetailUserEmail(e.target.value)}
                            required />
                        <input
                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            type="text"
                            placeholder="Phone*"
                            value={retailUserPhone}
                            onChange={(e) => setRetailUserPhone(e.target.value)}
                            required />
                        <input
                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            type="text"
                            placeholder="Venue*"
                            value={retailUserVenue}
                            onChange={(e) => setRetailUserVenue(e.target.value)}
                            disabled />
                        <input
                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            type="text"
                            placeholder="Plate"
                            value={retailUserPlate}
                            onChange={(e) => setRetailUserPlate([e.target.value.toUpperCase()])} />
                    </div>

                </div><div className="absolute bottom-0 mx-12 right-0 mb-20 flex flex-row justify-end">
                        <SheetClose asChild>
                            <Button variant={"secondary"} className="w-44 border border-primary text-primaryText">Cancel</Button>
                        </SheetClose>
                        <Button variant={"default"} isLoading={isLoading} onClick={handleBookingUpdate} className="w-44 ml-4 text-white">Save Changes</Button>
                    </div></>
            )}
     {bookingType === "Multi" || bookingType === "Single"  ?     <><div>
                <Text as="h3">Booking Type</Text>
                <div className="flex flex-row space-x-4 mt-6">
                    <Button onClick={handleClickSingle} variant={bookingType === "Single" ? "default" : "secondary"} className={`w-44 border-primary ${bookingType === "Single" ? "text-white" : "border-primary text-primaryText"}`}>Single Booking</Button>
                    <Button onClick={handleClickMulti} variant={bookingType === "Multi" ? "default" : "secondary"} className={`w-44 ${bookingType === "Multi" ? "text-white" : "border-primary text-primaryText"}`}>Multi Booking</Button>
                </div>
            </div>
            <Text className="mt-10" as="h3">Booking Date</Text>
            <div className="mt-6">
                    {bookingType === "Single" ? <DatePicker setNewDate={(value) => handleStartDate(value)} initialDate={startDate} /> : <DatePickerWithRange setStartDate={(val) => handleStartDate(val)} setEndDate={(val) => handleEndDate(val)} startDate={startDate} endDate={endDate} />}
                </div><div className="mt-4 flex flex-row">
                    <div className="flex flex-col">
                        <Text as="h3">Start Time</Text>
                        <div className="mt-6">
                            <div className="flex flex-row space-x-4">
                                <TimePicker initialTime={startTime} onChange={handleStartTime} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col ml-6">
                        <Text as="h3">End Time</Text>
                        <div className="mt-6 ">
                            <div className="flex flex-row space-x-4">
                                <TimePicker initialTime={endTime} onChange={handleEndTime} />
                            </div>
                        </div>
                    </div>
                </div><Text className="mt-10" as="h3">Valid Plates</Text><div className="flex flex-row space-x-8 mt-6 items-center">{booking.plate!.map((plate) => {
                    return (
                        <Button variant="default" className="w-44 text-white">{plate}</Button>
                    );
                }, [])}</div><div className="absolute bottom-0 mx-12 right-0 mb-20 flex flex-row justify-end">
                    <SheetClose asChild>
                        <Button variant={"secondary"} className="w-44 border border-primary text-primaryText">Cancel</Button>
                    </SheetClose>
                    <Button variant={"default"} isLoading={isLoading} onClick={handleBookingUpdate} className="w-44 ml-4 text-white">Save Changes</Button>
                </div></> :null}
        </>
    );
}

export default EditBookingBody;

// import { BookingsData, editMultiBooking, editSingleBooking } from "../../../../utils/apis/bookings_repository";
// import { Text } from "../../Text";
// import { DatePicker } from "../../inputs/DatePicker";
// import { getDateObject, getTime, addDays, getDateTimeString } from "../../../../utils/helper";
// import { useEffect, useState } from "react";
// import { Button } from "../../CustomButton";
// import { TimePicker } from "../../inputs/TimePicker";
// import { DatePickerWithRange } from "../../inputs/DateRangePicker";
// import { SheetClose } from "../../Sheets";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import { showToast } from "../../../../utils/toast";
// import { DateTime } from "luxon";

// interface EditBookingBodyProps {
//     booking: Partial<BookingsData>;
//     bookingId: string;
//     setSheetState: (state: boolean) => void;
// }

// const EditBookingBody = ({ booking, bookingId, setSheetState }: EditBookingBodyProps) => {
//     const queryClient = useQueryClient();

//     const [bookingType, setBookingType] = useState(booking.bookingType);
//     const [startTime, updateStartTime] = useState<string>(getTime(booking.startTime!));
//     const [endTime, updateEndTime] = useState<string>(getTime(booking.endTime!));
//     const [startDate, setStartDate] = useState<Date>(getDateObject(booking.startTime!));
//     const [endDate, setEndDate] = useState<Date>(getDateObject(booking.endTime!));
//     const [retailUserName, setRetailUserName] = useState(booking.name);
//     const [retailUserEmail, setRetailUserEmail] = useState(booking.email);
//     const [retailUserPhone, setRetailUserPhone] = useState(booking.phone);
//     const [retailUserPlate, setRetailUserPlate] = useState(booking.plate);

//     const [isLoading, setIsLoading] = useState(false);
//     const handleClickMulti = () => {
//         setBookingType("Multi");
//     }

//     const handleClickSingle = () => {
//         setBookingType("Single");
//     }

//     const handleStartTime = (time: string) => {
//         updateStartTime(time);
//     }

//     const handleEndTime = (time: string): void => {
//         updateEndTime(time);
//     }

//     const handleStartDate = (date: Date) => {
//         setStartDate(date);
//     }

//     const handleEndDate = (date: Date) => {
//         setEndDate(date);
//     }


//     const singleBookingMutation = useMutation({
//         mutationFn: editSingleBooking,
//         onSuccess: (val) => {
//             console.log("Loading state", isLoading)
//             if (val) {
//                 console.log("<<== single booking updated successfully ==>>");
//                 queryClient.invalidateQueries({ queryKey: ['bookings'] });
//                 setSheetState(false);
//             } else {
//                 setTimeout(() => {
//                     console.log("<<== booking update failed ==>>");
//                     queryClient.invalidateQueries({ queryKey: ['bookings'] });
//                     setSheetState(false);
//                 }, 2000);
//             }
//         },
//     })

//     const multiBookingMutation = useMutation({
//         mutationFn: editMultiBooking,
//         onSuccess: () => {
//             console.log("<<== multi booking updated successfully ==>>");
//             queryClient.invalidateQueries({ queryKey: ['bookings'] });
//             setSheetState(false);
//         },
//     })

//     const handleBookingUpdate = async () => {

//         const now = DateTime.now();

//         if ((!startDate || !endDate) && bookingType === "Multi") {
//             showToast("One or more date fields are empty", {
//                 type: "warning"
//             });
//             return;
//         }

//         if (!startDate && bookingType === "Single") {
//             showToast("Start date is empty", {
//                 type: "warning"
//             });
//             return;
//         }

//         if (!startTime) {
//             showToast("Start time is empty", {
//                 type: "warning"
//             });
//             return;
//         }

//         if (!endTime) {
//             showToast("End time is empty", {
//                 type: "warning"
//             });
//             return;
//         }


//         const updatedStartDate = getDateTimeString(startDate.toISOString(), startTime);
//         const updatedEndDate = getDateTimeString(bookingType === "Single" ? startDate.toISOString() : endDate.toISOString(), endTime);

//         if(DateTime.fromJSDate(new Date(updatedStartDate)) < now) {
//             showToast("Start time cannot be in the past", {
//                 type: "warning"
//             });
//             return;
//         }

//         if(DateTime.fromJSDate(new Date(updatedStartDate)) > DateTime.fromJSDate(new Date(updatedEndDate))) {
//             showToast("Start date cannot be after than the end date", {
//                 type: "warning"
//             });
//             return;
//         }

//         setIsLoading(true);

//         if(bookingType === "Single"){
//             const updatedBooking: Partial<BookingsData> = {
//                 bookingId: bookingId,
//                 startTime: updatedStartDate,
//                 endTime: updatedEndDate,
//             }
//             singleBookingMutation.mutate(updatedBooking);
//         }else if(bookingType === "Multi"){
//             const updatedBooking: Partial<BookingsData> = {
//                 multiID: booking.multiID,
//                 startTime: updatedStartDate,
//                 endTime: updatedEndDate,
//             }
//             multiBookingMutation.mutate(updatedBooking);
//         }
//     }
//     useEffect(() => {
// console.log("bboking ", booking)
//     })
//     const singleBookingMutation = useMutation({
//         mutationFn: editSingleBooking,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ["bookings"] });
//             setSheetState(false);
//         },
//     });

//     const multiBookingMutation = useMutation({
//         mutationFn: editMultiBooking,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ["bookings"] });
//             setSheetState(false);
//         },
//     });

//     const handleBookingUpdate = async () => {
//         if (!startDate || !startTime || !endTime) {
//             showToast("Please fill in all required fields.", { type: "warning" });
//             return;
//         }

//         const updatedStartDate = getDateTimeString(startDate.toISOString(), startTime);
//         const updatedEndDate = getDateTimeString(endDate.toISOString(), endTime);

//         if (DateTime.fromJSDate(new Date(updatedStartDate)) < DateTime.now()) {
//             showToast("Start time cannot be in the past", { type: "warning" });
//             return;
//         }

//         setIsLoading(true);

//         const updatedBooking: Partial<BookingsData> = {
//             bookingId,
//             bookingType,
//             startTime: updatedStartDate,
//             endTime: updatedEndDate,
//             // valetLocation: bookingType === "VALET" ? valetLocation : undefined,
//         };

//         if (bookingType === "VALET") {
//             singleBookingMutation.mutate(updatedBooking);
//         } else if (bookingType === "Multi") {
//             multiBookingMutation.mutate(updatedBooking);
//         } else {
//             singleBookingMutation.mutate(updatedBooking);
//         }
//     };

//     return (
//         <div className="scrollable-content" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
//             <div>
//                 <Text as="h3">Booking Type</Text>
//                 <div className="flex flex-row space-x-4 mt-6">
//                 <Button
//     onClick={() => setBookingType("Single")}
//     variant={bookingType === "Single" ? "default" : "secondary"}
//     className={`w-44 ${bookingType === "Single" ? "text-white" : "text-primaryText"}`}
//     style={{ display: bookingType === "Guest" ? "none" : "block" }}
// >
//     Single Booking
// </Button>
// <Button
//     onClick={() => setBookingType("Multi")}
//     variant={bookingType === "Multi" ? "default" : "secondary"}
//     className={`w-44 ${bookingType === "Multi" ? "text-white" : "text-primaryText"}`}
//     style={{ display: bookingType === "Guest" ? "none" : "block" }}
// >
//     Multi Booking
// </Button>
// <Button
//     onClick={() => setBookingType("Guest")}
//     variant={bookingType === "Guest" ? "default" : "secondary"}
//     className={`w-44 ${bookingType === "Guest" ? "text-white" : "text-primaryText"}`}
//     style={{ display: bookingType === "Guest" ? "block" : "none" }}
// >
//     Valet Booking
// </Button>

//                 </div>
//             </div>

//             <Text className="mt-10" as="h3">Booking Date</Text>
//             <div className="mt-6">
//                 {bookingType === "Single" || bookingType === "Guest" ? (
//                     <DatePicker setNewDate={setStartDate} initialDate={startDate} />
//                 ) : (
//                     <DatePickerWithRange setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
//                 )}
//             </div>

//             <div className="mt-4 flex flex-row" style={{ display: bookingType === "Guest" ? "none" : "block" }}>
//                 <div className="flex flex-col">
//                     <Text as="h3">Start Time</Text>
//                     <TimePicker initialTime={startTime} onChange={updateStartTime} />
//                 </div>
//                 <div className="flex flex-col ml-6">
//                     <Text as="h3">End Time</Text>
//                     <TimePicker initialTime={endTime} onChange={updateEndTime} />
//                 </div>
//             </div>

//             {bookingType === "Guest" && (
//               <div className="mt-8">
//               <Text as="h3">Retail User Details</Text>
//               <div className="flex flex-col gap-4 mt-6">
//               <input
//                               className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
//                               type="text"
//                               placeholder="Name*"
//                               value={retailUserName}
//                               onChange={(e) => setRetailUserName(e.target.value)}
//                               required
//                           />
//                           <input
//                               className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
//                               type="text"
//                               placeholder="Email*"
//                               value={retailUserEmail}
//                               onChange={(e) => setRetailUserEmail(e.target.value)}
//                               required
//                           />
//                            <input
//                               className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
//                               type="text"
//                               placeholder="Phone*"
//                               value={retailUserPhone}
//                               onChange={(e) => setRetailUserPhone(e.target.value)}
//                               required
//                           />
//                            <input
//                               className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
//                               type="text"
//                               placeholder="Plate"
//                               value={retailUserPlate}
//                             //   onChange={(e) => setRetailUserPlate(e.target.value.toUpperCase())}
//                           />
//               </div>

//           </div>
//             )}

//             <div className="absolute bottom-0 mx-12 right-0 mb-20 flex flex-row justify-end">
//                 <SheetClose asChild>
//                     <Button variant="secondary" className="w-44 text-primaryText">
//                         Cancel
//                     </Button>
//                 </SheetClose>
//                 <Button variant="default" isLoading={isLoading} onClick={handleBookingUpdate} className="w-44 ml-4 text-white">
//                     Save Changes
//                 </Button>
//             </div>
//         </div>
//     );
// };

// export default EditBookingBody;
