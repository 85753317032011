import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DirectDebitContainer from "../../components/ui/containers/DirectDebitContainer";
import { fetchDirectDebitDetailsByEmail, fetchPaymentDetailsByEmail, getAllCustomers, removePaymentDetails } from '../../utils/apis/payment_repository';
import { getUserFromLocalStorage } from '../../utils/helper';
import { showToast } from "../../utils/toast";
import StripeContainer from "../../components/ui/containers/StripeContainer";
interface PaymentDebitMethod {
    id: string;
    type: string;
    au_becs_debit?: {
      bsb_number: string;
      fingerprint: string;
      last4: string;
    };
    billing_details: {
      name: string | null;
      email: string | null;
      phone: string | null;
    };
    customer: string;
  }
  interface PaymentCardMethod {
    id: string;
    type: string;
    card?: {
      brand: string;
      exp_month: number;
      exp_year: number;
      last4: string;
    };
    billing_details: {
      name: string | null;
      email: string | null;
      phone: string | null;
    };
    customer: string;
  }
const PaymentMethods = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<PaymentDebitMethod[]>([]);
  const [savedCardPaymentMethods, setCardSavedPaymentMethods] = useState<PaymentCardMethod[]>([]);
  const [activeTab, setActiveTab] = useState('creditCard'); 
  const [creditCustomerId, setCreditCustomerId] = useState("");
  const [debitCustomerId, setDebitCustomerId] = useState("");

  const currentUser = getUserFromLocalStorage();
  const removePaymentMethod = async (id: string) =>{
         
    const success =   await removePaymentDetails(id)
         if (success) {
          showToast("Payment details removed successfully", { type: "success" });
          // Remove the payment method from the state
          if (activeTab === 'directDebit') {
           fetchDebitDetails()
          } else {
            fetchcreditdetails()
          }
        } else {
          showToast("Payment details removal failed", { type: "error" });
        }
      }
  useEffect(() => {
        if (location.pathname === "/payment/card") {
            setActiveTab('creditCard');
        } else {
            setActiveTab('directDebit');
        }
}, [location.pathname]);

  useEffect(() => {
    if (currentUser?.email) {
      (async () => {
       fetchcreditdetails();
       fetchDebitDetails();
      })();
    }
  }, [currentUser?.email]);


  const renderPaymentMethods = () => {
 

  if (!savedPaymentMethods) {
    return <div className="text-sm text-gray-500">No saved payment methods available.</div>;
  }

    return (
        <div className="flex flex-col space-y-4 w-full">
        { activeTab === 'directDebit' ? savedPaymentMethods.map((method) => (
          <div key={method.id} className="flex flex-row justify-between items-center bg-white rounded-md p-6 shadow-md">
            <div>
              <div className="text-sm font-semibold text-gray-800 capitalize">
                {method.type?.replace('_', ' ') || 'Type not available'}
              </div>
              {method.type === "au_becs_debit" && method.au_becs_debit && (
                <div className="text-xs text-gray-600">
                  BSB: <span className="font-semibold text-blue-500">{method.au_becs_debit.bsb_number}</span>,
                  Last 4: <span className="font-semibold text-blue-500">{method.au_becs_debit.last4}</span>
                </div>
              )}
              <div className="text-xs text-gray-600">Name: {method.billing_details?.name || 'Name not available'}</div>
            </div>
            <button
              className="px-4 py-2 bg-red-500 text-white text-sm rounded-md hover:bg-red-600"
              onClick={() => removePaymentMethod(method.id)}
            >
              Remove
            </button>
          </div>
        )): savedCardPaymentMethods.map((method) => (
          <div key={method.id} className="flex flex-row justify-between items-center bg-white rounded-md p-6 shadow-md">
            <div>
              <div className="text-sm font-semibold text-gray-800 capitalize">
                {method.type?.replace('_', ' ') || 'Type not available'}
              </div>
              {method.type === "card" && method.card && (
                <div className="text-xs text-gray-600">
                  Card type: <span className="font-semibold text-blue-500">{method.card.brand}</span>,
                  Expiry Month: <span className="font-semibold text-blue-500">{method.card.exp_month}</span>,
                  Expiry Year: <span className="font-semibold text-blue-500">{method.card.exp_year}</span>,
                  Last 4: <span className="font-semibold text-blue-500">{method.card.last4}</span>
                </div>
              )}
              <div className="text-xs text-gray-600">Name: {method.billing_details?.name || 'Name not available'}</div>
            </div>
            <button
              className="px-4 py-2 bg-red-500 text-white text-sm rounded-md hover:bg-red-600"
              onClick={() => removePaymentMethod(method.id)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
  
    );
  };
  const fetchcreditdetails = async () => {
    try {
      const paymentDetails = await fetchPaymentDetailsByEmail(currentUser.email);
      if (paymentDetails && paymentDetails.paymentMethods) {
          setCardSavedPaymentMethods(paymentDetails.paymentMethods);
          setCreditCustomerId(paymentDetails.id)
          // console.log("oooooooooooo " , paymentDetails.paymentMethods[0].id)
      }
  } catch (error) {
      console.error("Failed to fetch and set payment details:", error);
  }
  }

  const fetchDebitDetails = async () => {
    try {
      const paymentDetails = await fetchDirectDebitDetailsByEmail(currentUser.email);
      if (paymentDetails && paymentDetails.paymentMethods) {
        // Update the saved payment methods state with the received data
        setSavedPaymentMethods(paymentDetails.paymentMethods);
        setDebitCustomerId(paymentDetails.id)

      }
    } catch (error) {
      console.error("Failed to fetch and set payment details:", error);
    }
  }

  const handlePaymentMethodSaved = async (methodType: string) => {
    switch(methodType){
      case "Credit card":
        fetchcreditdetails();
        break;
      case "Direct debit":
        fetchDebitDetails();
        break;
      default:
        console.log("default type")
    }

   
  };
  
  const renderForm = () => {
    if (location.pathname === "/payment/card") {

      return  <StripeContainer
      isRestaurant={true}
      selectedDate=''
      venue=''
      phone=''
      plateNumber=''
      email=''
      bookingId=''
      status={true}
      startHour = ''
      endHour = ''
      onSaveSuccess={() => handlePaymentMethodSaved("Credit card")}
  />
    } else if (location.pathname === "/payment/direct-debit") {
      return <DirectDebitContainer onSaveSuccess={() => handlePaymentMethodSaved("Direct debit")} />
     
    }
  };

 
    return (
        <div className="flex flex-row mx-14 mt-6">
          <div className="w-2/3 bg-white rounded-md p-6 shadow-md">
          <div className="text-xl font-bold mb-4">Manage Payment Methods</div>
            <div className="text-xl font-bold mb-4 flex space-x-4">
              <button
                className={`px-4 py-2 w-1/2 mr-2 ${location.pathname === "/payment/card" ? "bg-primary text-sm font-medium text-white" : "text-sm font-medium bg-gray-200"} rounded-md`}
                onClick={() => {navigate("/payment/card"); setActiveTab("creditCard")}}
              >
                Credit Card
              </button>
              <button
                className={`px-4 py-2 w-1/2 ${location.pathname === "/payment/direct-debit" ? "bg-primary text-sm font-medium text-white" : "text-sm font-medium bg-gray-200"} rounded-md`}
                onClick={() =>{ navigate("/payment/direct-debit"); setActiveTab("directDebit")}}
              >
                Direct Debit
              </button>
            </div>
            {renderPaymentMethods()}
          </div>
          <div className="w-1/3 ml-6">
            {location.pathname === "/payment/card" ? (
              <div className="flex flex-col w-full bg-white rounded-md p-6 shadow-md">
             
                {renderForm()}
              </div>
            ) : location.pathname === "/payment/direct-debit" ? (
              <div className="flex flex-col w-full bg-white rounded-md p-6 shadow-md">
                <div className="text-lg font-semibold mb-4">Add Bank Details</div>
                {renderForm()}
              </div>
            ) : null}
          </div>
        </div>
      );
    };

export default PaymentMethods;
